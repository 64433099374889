const NotFound = () => {
  return (
    <div className="main-container">
      <div className="nes-container is-centered" id="error">
        NotFound
      </div>
    </div>
  );
};

export default NotFound;
