import React from "react";
/* import "../../../style/main.css"; */
/* import "nes.css/css/nes.min.css"; */
import "./iconsm.css";

const Listed = () => {
  return (
    <div className="listeda">
      <img
        className="Icon_spritesheet_listed pixelart listed"
        src={require("./listed.png")}
        alt="Listed"
      />
    </div>
  );
};

export default Listed;
